import './list.scss';
import dataApi from '@/api/system/data.js';

export default {
  name: 'dict-data-list',
  components: {},
  data() {
    return {
      page: {
        pageSize: 10,
        pageSizes: [3, 10, 20, 30, 40, 50, 100]
      },
      searchData: {},
      tableData: [],
      tbleOption: {
        align: 'center',
        cellBtn: false,
        ...this.$store.getters.tableConfig,
        column: [
          {
            // 字典编码   dict_code
            label: '字典编码',
            addDisplay: false,
            editDisplay: false,
            hide: true,
            prop: 'dict_code'
          },
          {
            // 字典标签   dict_label
            label: '字典标签',
            prop: 'dict_label'
          },
          {
            // 字典键值  dict_value
            label: '字典键值 ',
            prop: 'dict_value'
          },
          {
            // 字典类型  dict_type
            label: '字典类型 ',
            addDisplay: false,
            editDisplay: false,
            prop: 'dict_type'
          },
          {
            // 样式属性（其他样式扩展）  css_class
            label: '样式属性',
            prop: 'css_class'
          },
          {
            // 表格回显样式   list_class
            label: '表格回显样式 ',
            prop: 'list_class'
          },
          {
            // 是否默认（Y是 N否）
            label: '是否默认',
            row: true,
            // span: 12,
            type: 'radio',
            value: 'Y',
            dicData: [
              {
                label: '是',
                value: 'Y'
              },
              {
                label: '否',
                value: 'N'
              }
            ],
            prop: 'stais_defaultus'
          },
          {
            // 状态（0正常 1停用）
            label: '状态',
            row: true,
            // span: 12,
            type: 'radio',
            value: '1',
            dicData: [
              {
                label: '正常',
                value: '1'
              },
              {
                label: '停用',
                value: '-1'
              }
            ],
            prop: 'status'
          },
          {
            // 创建时间 create_time
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          },
          {
            // 备注 remark
            label: '备注',
            prop: 'remark',
            span: 24,
            type: 'textarea'
          }
        ]
      }
    };
  },
  computed: {},
  created() {
    console.log('id', this.$route.params.id);
  },
  mounted() {
    this.getList();
  },
  filters: {},
  methods: {
    // 返回
    goBack() {
      this.$router.go(-1);
    },
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange(params, done) {
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 添加
    handleSubmit(form, done, loading) {
      const postData = { ...form, dict_type: this.$route.params.id };
      console.log('postData', postData);
      dataApi.add.r(postData).then((res) => {
        console.log(res);
        done(form);
        this.getList();
        loading();
      });
      done();
    },
    // 删除
    rowDel(form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ dict_code: form.dict_code }).then((res) => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    // 修改
    rowUpdate(form, index, done, loading) {
      console.log('index', form, index);
      dataApi.update.r(form).then((res) => {
        console.log(res);
        done(form);
        this.getList();
        loading();
      });
      done();
    },
    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        dict_type: this.$route.params.id,
        ...this.searchData
      };
      dataApi.getList.r(postData).then((res) => {
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
